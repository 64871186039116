@import "tachyons/tachyons.scss";

body {
  @extend .avenir;
}

nav {
  @extend .pa3, .pa5-ns;

  a {
    @extend .link, .dim, .gray, .f6, .f5-ns, .dib, .mr3;

    &:first-child {
      @extend .black, .b;
    }
  }
}

article.index {
  @extend .cf, .ph3, .ph5-ns, .pv5;

  ul {
    @extend .fn, .fl-ns, .pr4-ns, .list, .pl0, .mt0;

    h2 {
      @extend .f3, .lh-title, .mb1;
    }

    a {
      @extend .link, .dim, .black;
    }

    time {
      @extend .f6, .ttu, .tracked, .gray;
    }
  }
}

article.post {
  @extend .cf, .ph3, .ph5-ns, .pv5;

  header {
    @extend .fn, .fl-ns, .w-50-ns, .pr4-ns;

    h1 {
      @extend .f2, .lh-title, .fw9, .mb3, .mt0, .pt3, .bt, .bw2;
    }

    h2 {
      @extend .f3, .mid-gray, .lh-title;
    }

    time {
      @extend .f6, .ttu, .tracked, .gray;
    }
  }

  ul {
    @extend .fn, .fl-ns, .w-50-ns, .pl3, .pl0-ns, .mt0-ns;

    p {
      @extend .f5, .lh-copy, .measure, .mt0-ns;
    }
  }

  pre {
    @extend .pre;
  }

  :target p, p:target {
    @extend .pa1, .nl1, .nr1;
    background: $light-yellow;
  }

  img {
    @extend .db, .center;
  }
}

:root {
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  nav a {
    color: $light-silver;

    &:first-child {
      color: $white;
    }
  }

  article.index ul a {
    color: $white;
  }

  article.post header h2 {
    color: $light-silver;
  }

  article.post a {
    color: $light-blue;

    &:visited {
      color: $blue;
    }
  }

  article.post :target p, article.post p:target {
    background: $dark-gray;
  }

  article.index {
    --pagefind-ui-primary: #eeeeee;
    --pagefind-ui-text: #eeeeee;
    --pagefind-ui-background: #152028;
    --pagefind-ui-border: #152028;
    --pagefind-ui-tag: #152028;
  }
}

@import "default.css";
